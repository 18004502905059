import { Flex, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { deletePhysicalNexus, PHYSICAL_NEXUS_KEY } from 'apis/physical-nexus-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { USStatesByCode } from 'constants/app-constants';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';

type RemovePhysicalNexusProps = {
  physicalNexusId: string;
  stateName: string;
  onClose: () => void;
};

export const RemovePhysicalNexus = ({ physicalNexusId, stateName, onClose }: RemovePhysicalNexusProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { mutateAsync: doDeleteNexus, isPending } = useMutation({
    mutationFn: () => {
      return deletePhysicalNexus(orgId, physicalNexusId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PHYSICAL_NEXUS_KEY] });
      queryClient.invalidateQueries({ queryKey: [ONBOARDING_STATE_KEY] });
      onClose();
      handleSuccessNotification('State has been successfully removed.');
    },
  });

  const handleSubmit = async () => {
    await doDeleteNexus();
  };

  return (
    <DialogRoot
      size={'lg'}
      open={Boolean(physicalNexusId)}
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{`Are you sure you want to remove ${USStatesByCode[stateName as keyof typeof USStatesByCode]?.label}?`}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Text>
            This will remove your physical presence from this location and could mean you will not need to file with
            this jurisdiction, we will update your Nexus accordingly.
          </Text>
        </DialogBody>
        <DialogFooter>
          <Flex gap={3}>
            <Button variant={'outline'} color={'secondary'} onClick={onClose}>
              Cancel
            </Button>
            <Button loading={isPending} variant={'danger'} _hover={{ bg: '#C53030' }} onClick={handleSubmit}>
              Confirm
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
