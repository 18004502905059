import { useDisclosure } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { PhysicalNexusModal } from 'pages/Presence/components/physical-nexus-modal';

export const PhysicalNexusToolbar = () => {
  const { onOpen, open, onClose } = useDisclosure();

  return (
    <>
      <Button variant={'solid'} colorPalette="blue" width={'100px'} onClick={onOpen}>
        New
      </Button>
      {open && <PhysicalNexusModal isOpen={open} onClose={onClose} />}
    </>
  );
};
